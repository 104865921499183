<template>
  <div class="users-permissions-table card">
   <table class="table">
      <thead class="table-header">
        <tr class="table-row">
          <th class="table-header-cell"></th>
          <th class="table-header-cell"
              v-for="column in columns"
              :key="column.key">
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr class="table-row table-row-hover"
            v-for="userPermissions in editableUsersPermissions"
            :key="userPermissions.user_id">
          <td class="table-cell">
            <div class="user-info">
              {{ userPermissions.nickName }},
            </div>
            <div class="user-info">
              {{ userPermissions.email }}
            </div>
          </td>
          <td class="table-cell"
              v-for="column in columns"
              :key="column.key">
            <template v-if="column.type === 'boolean'">
              <base-checkbox class="centered"
                             v-model="userPermissions[column.key]"
                             @input="resetCheckingPermission(userPermissions); updateUsersPermissions();"
                             v-if="column.key === 'is_admin'"/>

              <base-checkbox class="centered"
                             v-model="userPermissions.capabilities[column.key]"
                             @input="updateUsersPermissions"
                             v-else-if="column.key !== 'is_checking_required' || !userPermissions.is_admin"/>
            </template>

            <base-text-box class="textbox"
                           type="number"
                           v-model="userPermissions.capabilities[column.key]"
                           @input="updateUsersPermissions"
                           v-if="column.type === 'number'"/>
          </td>
        </tr>

        <tr class="table-row no-results"
            v-if="!editableUsersPermissions.length">
          <td class="table-cell" colspan="6">
            {{ $t('usersPermissions.table.noResults') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    model: {
      prop: 'usersPermissions',
      event: 'update'
    },

    props: {
      usersPermissions: {
        required: true,
        type: Array
      },
    },

    data() {
      return {
        editableUsersPermissions: [],
      }
    },

    methods: {
      resetCheckingPermission(userPermissions) {
        userPermissions.capabilities.is_checking_required = false
      },

      updateUsersPermissions(event) {
        this.$emit('update', _.cloneDeep(this.editableUsersPermissions))
      },
    },

    computed: {
      columns() {
        const columns = this.$t('stylistPermissions')

        return this.$currentUser.isAdmin ? columns : columns.filter(column => column.key !== 'is_admin')
      }
    },

    watch: {
      usersPermissions: {
        handler(value) {
          this.editableUsersPermissions = _.cloneDeep(value)
        },
        deep: true,
        immediate: true
      },
    }
  }
</script>

<style lang="scss" scoped>
  .textbox {
    margin: 0;
  }

  .user-info {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
