<template>
  <div class="users-permissions">
    <div class="users-permissions-container">
      <div class="header">
        <div class="header-title heading-accent">
          {{ $currentUser.isAdmin ? $t('usersPermissions.title.admin') : $t('usersPermissions.title.mainStylist') }}
        </div>

        <div class="header-tools">
          <base-text-box-search class="search"
                                :placeholder="$t('usersPermissions.searchPlaceholder')"
                                v-model="params.search_text"
                                @input="onSearchInput"/>
          <base-button :loading="applyingChanges"
                       @click="applyChanges">
            {{ $t('usersPermissions.applyButton') }}
          </base-button>
        </div>
      </div>

      <div class="content">
        <users-permissions-table v-model="usersPermissions"/>
      </div>
    </div>
  </div>
</template>

<script>
  import UsersPermissionsTable from './UsersPermissionsTable'
  import profileService from '@/services/queries/profileQueries.js'

  export default {
    components: {
      UsersPermissionsTable
    },

    data() {
      return {
        usersPermissions: [],
        params: {
          user_type: 'stylist',
          search_text: null
        },
        applyingChanges: false,
      }
    },

    metaInfo() {
      return {
        title: this.$currentUser.isAdmin ?
          this.$t('usersPermissions.pageTitle.admin') :
          this.$t('usersPermissions.pageTitle.mainStylist')
      }
    },

    created() {
      if (this.$currentUser.isStylist) {
        this.params['user_id.exclude'] = 'me'
        this.params.is_admin = false
      }

      this.getUsersPermissions()
    },

    methods: {
      getUsersPermissions() {
        profileService.get(this.params).then(response => {
          this.usersPermissions = response.items.map(item => {
            let userPermissions = _.pick(item, ['user_id', 'nickName', 'email', 'capabilities'])

            return this.$currentUser.isAdmin ? { ...userPermissions, is_admin: item.is_admin } : userPermissions
          })
        })
      },

      onSearchInput: _.debounce(function() {
        this.getUsersPermissions()
      }, 500),

      applyChanges() {
        this.applyingChanges = true

        profileService.updateMultiple(this.usersPermissions).then(() => {
            this.applyingChanges = false
          }).catch(() => {
            this.applyingChanges = false
          })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .users-permissions {
    display: flex;
    justify-content: center;
    background-color: #F8F8F8;

    .users-permissions-container {
      width: 100%;
      max-width: 1300px;
      padding: 30px;
    }

    .header {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      background-color: #F8F8F8;

      .header-title {
        margin: 0;
      }

      .header-tools {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .search {
        width: 300px;
        margin-right: 15px;
      }
    }
  }
</style>
